//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 50%); 	// #555
$gray-light:            #eef0f1;   // #999
$gray-lighter:           lighten($gray-base, 96%); 	// #eee
$gray-500:               #e6e6e6;
$gray-400:               #ddd;

$brand-primary:         #d97043;
$brand-second:         #d97043;
$brand-success:         #afc803;
$brand-info:            #41aae1;
$brand-warning:         #f26522;
$brand-danger:          #cf2d13;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #f5f5f5;
//** Global text color on `<body>`.
$text-color:            $gray;
//** Global titles color.
$headings-color:        $brand-second;

//** Global textual link color.
$link-color:            $brand-second;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 20%);
//** Link hover decoration.
$link-hover-decoration: none;
