.bg-home1 {
  background-image: url(../images/charte/home1.jpg);
  max-width: 100%;
  height: auto;
}

.divider {
	height: 20px;
	background: url(../images/charte/divider.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
}


.bg-white {
	background: url(../images/charte/bg-texture.jpg) no-repeat center;
	background-size: cover;
}
.bg-white2 {
	background: white;
}

.img-top {
  top: -50px;
  position: absolute;
}

.bord1{
	position: absolute;
	top: 0;
	left: 0;
	width: 18px;
	height: 18px;
	background: url(../images/charte/bord1.png) no-repeat center;
	background-size: cover;
}

.bord2{
	position: absolute;
	top: 0;
	right: 0;
	width: 18px;
	height: 18px;
	background: url(../images/charte/bord3.png) no-repeat center;
	background-size: cover;

}

.bord3{
	position: absolute;
	bottom: 0;
	right: 0;
	width: 18px;
	height: 18px;
	background: url(../images/charte/bord4.png) no-repeat center;
	background-size: cover;
	
}

.bord4{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 18px;
	height: 18px;
	background: url(../images/charte/bord2.png) no-repeat center;
	background-size: cover;
}


.ta {
	@extend .text-left;
	margin: 0 0 20px 0;
	padding: 25px 25px 25px 25px;
	position: relative;
	color: $brand-primary;
	text-transform: uppercase;
	font-weight: bolder;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 25px;
		width: 4rem;
		height: 4px;
		background-color: $brand-primary;
	}
}

.btn-fleche {
	@extend .btn-accueil;
	background: $brand-primary;
	white-space: normal;
	position: relative;
	padding: 10px 50px;
	border: 2px solid;
	color: white;
	font-weight: bold;
	border-radius: 50px;
	border: solid 1px $brand-primary;
	margin-bottom: 50px;
	-webkit-transition: all .2s ease-out;
	transition: all .2s ease-out;


	&:hover {
		background: white;
		color: $brand-primary;
		border-radius: 50px;
		border: solid 1px $brand-primary;
		padding-right: 70px;
	}

	&::after {
		position: absolute;
		margin-left: 20px;
		content: "\f061";
		font-family: fontAwesome;
		color: $brand-primary;
		-webkit-transition: all .8s ease-out .0s;
		transition: all .8s ease-out .0s;
	}
}
