


/////////////////////////////////////// STYLE TEMPLATE DPE =>  https://codepen.io/chibani/pen/pEEMRZ ////////////////////////
/// 
$zone-width:300px; // The DPE zone's width
$base-font-size: 12px; // might not suits every sizing...
$arrow-height: 25px;
$letter-a-width: 15%; // Length of the first "arrow" (the "A" one)
$letters-step: 5%; // difference between 2 arrows' length

@mixin letter-code($letter, $width, $bgcolor){
  .letter-#{$letter}{
      width: 100%;
    
      .line{
        width: $width;
      }
            
      .line,
      .line::after,
      .sticker,
      .sticker::before{
        background-color: $bgcolor;
      } 
    }
  
  &.#{$letter} .letter-#{$letter} .sticker{
    display: block;
  }
}

.dpe{
  color: #555;
  min-width: $zone-width;
  font-size: $base-font-size;
  
  border: 1px solid #ccc;
  padding: 10px;
  
  .title{
    text-align:center;
    text-transform: uppercase;
    font-size: 1em;
  }
  
  .row{
    overflow:hidden;
  }
  
  .information{
    font-size: 0.8em;
  }
  
  .left{
    float:left;
  }
  .right{
    float:right;
  }


  .letters{
    color: #000;
    margin-top: 5px;

    >div{
      overflow:hidden;
      height: $arrow-height;
      margin-bottom: 0.4em;
      
      .line,
      .sticker{
        line-height: $arrow-height;
        height:$arrow-height;
        position: relative;
      }

      .line{
        position: relative;
        padding-left: 0.5em;
        
        float: left;

          em{
            font-size: 0.8em;
          }

          strong{
            //font-weight: normal;

            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
          }

          &::after{
            content:"";
            // display: block;
            // transform: rotate(-45deg);
            // transform-origin: bottom;
            // width: $arrow-height/1.3;
            // height: $arrow-height/1.3;
            position: absolute;
            right: -13px;
            top: 0em;
            // z-index: 0;
            display : inline-block;
            height : 0;
            width : 0;
            border-top : 13px solid white;
            border-bottom : 13px solid white;
            border-left : 13px solid transparent;
          }

        }
      
      }
    
      
      .sticker-label{
        @extend .sticker;
        line-height: 14px !important;
        font-weight: 700;
        small {
            display: block;
        line-height: 6px !important;
        font-weight: 600;
        }
      }
      .sticker{
          float: right;
          width: 7em;
          text-align: center;
          font-size: .8em;        
          display: none;
          line-height: 1;
          
          small {
            display: block;
            padding-top: 2px;
            font-size: .8em;
          }
        
        &::before{
          content:" ";
        //   display: block;
        //   transform: rotate(-45deg);
        //   transform-origin: bottom;
        //   width: $arrow-height/1.3;
        //   height: $arrow-height/1.3;

        //   position: absolute;
        //   left: -0.2em;
        //   top: 0em;
        //   z-index: -1;
        position: absolute;
        left: -13px;
        top: 0em;
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 13px solid white;
        border-bottom : 13px solid white;
        border-right : 13px solid transparent;
      }
    }

      
  }

    @include letter-code('a', $letter-a-width, #329837);
    @include letter-code('b', $letter-a-width+(1*$letters-step), #57af37);
    @include letter-code('c', $letter-a-width+(2*$letters-step), #c6d300);
    @include letter-code('d', $letter-a-width+(3*$letters-step), #f2e500);
    @include letter-code('e', $letter-a-width+(4*$letters-step), #ffcb03);
    @include letter-code('f', $letter-a-width+(5*$letters-step), #f39739);
    @include letter-code('g', $letter-a-width+(6*$letters-step), #e4251f);

    .letter-g,
    .letter-g .sticker{
      color: #fff;
    }
}


.dpgaz {
    @extend .dpe;

    .letter-a,
    .letter-a .sticker-active,
    .letter-b,
    .letter-b .sticker-active,
    .letter-c,
    .letter-c .sticker-active,
    .letter-d,
    .letter-d .sticker-active,
    .letter-e,
    .letter-e .sticker-active,
    .letter-f,
    .letter-f .sticker-active,
    .letter-g,
    .letter-g .sticker-active{
      color: #fff;
    }

    @include letter-code('a', $letter-a-width, #a4dbf8);
    @include letter-code('b', $letter-a-width+(1*$letters-step), #8cb4d3);
    @include letter-code('c', $letter-a-width+(2*$letters-step), #7792b1);
    @include letter-code('d', $letter-a-width+(3*$letters-step), #606f8f);
    @include letter-code('e', $letter-a-width+(4*$letters-step), #4d5271);
    @include letter-code('f', $letter-a-width+(5*$letters-step), #393551);
    @include letter-code('g', $letter-a-width+(6*$letters-step), #281b35);
}