
/* ==========================================================================
	Headers
	========================================================================== */

@import "headers/header-basic";
@import "headers/header-basic-2";
@import "headers/header-anim-1";
@import "headers/header-anim-2";
@import "headers/header-slider-3";
@import "headers/header-slider-4";

//habillage du diporama-charte avec l'élément span 'overlay-dark' dans diaporama-inc.asp
.overlay-dark {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	opacity: .3;
}
